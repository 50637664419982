export const configuration = {
	explorer: {
		modelPlateColor: '#777',
		lightColor: '#fff',
		soilColor: '#e9dac4',
		fogColor: '#999',
		gridColor: '#9d9d9d',
		waterColor: '#4a8ca1',
		showStats: false,
	},
	viewCube: {
		lineIdle: '#75838a',
		lineHover: '#7D8D94',
		textIdle: '#363636',
		textActive: '#999',
		textHover: 'F6F9FA',
	},
};
