import classNames from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useOnClickOutside } from 'usehooks-ts';
import {
	getDate,
	getDaysFromToday,
	getMonthShort,
	getToday,
	getYear,
} from '../../utils/Dates';
import { Icon } from '../Icon/Icon';
import './Calendar.scss';
import { CalendarContainer } from './CalendarContainer/CalendarContainer';
import { CalendarDateInput } from './CalendarDateInput';
import { CalendarHeader } from './CalendarHeader/CalendarHeader';

type CalendarProps = {
	startDate?: Date;
	endDate?: Date;
	onChange: (dates: [Date, Date]) => void;
};

export const Calendar: FC<CalendarProps> = ({
	startDate = new Date(),
	endDate,
	onChange,
}) => {
	const [show, setShow] = useState(false);

	// Internal represenatation of the two dates
	// The component emits only when two valid dates have been picked
	const [internalDateValues, setInternalDateValues] = useState<
		[Date | null, Date | null]
	>([startDate, endDate ?? null]);

	// Hide the datepicker when clicked outside
	const ref = useRef<HTMLInputElement>(null);
	useOnClickOutside(ref, () => {
		setShow(false);
	});

	/** Updates the internal date values only if they are in fact different */
	const setInternalValues = useCallback(
		(dates: [Date | null, Date | null]) => {
			// Only change if the new set of values are not equal to the old set
			if (
				areNullableDatesEqual(dates[0], internalDateValues[0]) &&
				areNullableDatesEqual(dates[1], internalDateValues[1])
			) {
				return;
			}

			setInternalDateValues(dates);
		},
		[internalDateValues]
	);

	const handleDatepickerInputChange = useCallback(
		(dates: [Date | null, Date | null]) => {
			setInternalValues(dates);
		},
		[setInternalValues]
	);

	const handleStartDateInputChange = useCallback(
		(date: Date) => {
			const [, endValue] = internalDateValues;

			setInternalValues([date, endValue]);
		},
		[internalDateValues, setInternalValues]
	);

	const handleEndDateInputChange = useCallback(
		(date: Date) => {
			const [startDate] = internalDateValues;

			setInternalValues([startDate, date]);
		},
		[internalDateValues, setInternalValues]
	);

	// When valid values are selected, publish them to the onChange event
	useEffect(() => {
		const start = internalDateValues[0];
		const end = internalDateValues[1];

		// Don't emit if any of the values are null
		if (start === null || end === null) {
			return;
		}

		// Don't emit if the values are the same
		if (start.getTime() === end.getTime()) {
			return;
		}

		onChange([start, end]);
	}, [internalDateValues, onChange]);

	const getUnfocusedValue = (): {
		start: string;
		startWithYear: string;
		end: string;
	} => {
		// Month - Date - Optional Year (If no end date)
		const formattedStartDate = startDate
			? `${getMonthShort(startDate)} ${getDate(startDate)}${
					!endDate ? ', ' + getYear(startDate) : ''
			  }`
			: '';

		const formattedStartWithYear = startDate
			? `${getMonthShort(startDate)} ${getDate(startDate)}${
					', ' + getYear(startDate)
			  }`
			: '';

		// Month - Date - Year
		const formattedEndDate = endDate
			? `${getMonthShort(endDate)} ${getDate(endDate)}, ${getYear(endDate)}`
			: '';

		return {
			start: formattedStartDate,
			startWithYear: formattedStartWithYear,
			end: formattedEndDate,
		};
	};

	return (
		<div className="Calendar" ref={ref}>
			<div className="Calendar__Input" onClick={() => setShow(true)}>
				<div
					className={classNames({
						Calendar__InputFields: true,
						'Calendar__InputFields--Active': show,
					})}
				>
					{show && (
						<>
							<CalendarDateInput
								date={startDate}
								onChange={handleStartDateInputChange}
								className="Calendar__InputElement"
							/>
							<p>-</p>
							<CalendarDateInput
								date={endDate}
								onChange={handleEndDateInputChange}
								className="Calendar__InputElement"
							/>
						</>
					)}

					{!show && (
						<p className="Calendar__DateValue">
							{getUnfocusedValue().start + ' - ' + getUnfocusedValue().end}
						</p>
					)}
					<div
						className={classNames(['Calendar__Icon'], {
							'Calendar__Icon--Open': show,
						})}
					>
						<Icon name="CaretDown" width={12} />
					</div>
				</div>

				{show && (
					<div className="Calendar__DatepickerWrapper">
						<Datepicker
							startDate={internalDateValues[0]}
							endDate={internalDateValues[1]}
							onChange={handleDatepickerInputChange}
							selectsRange={true}
							dateFormat="dd MMM yyyy"
							inline
							showPopperArrow={true}
							maxDate={getDaysFromToday(1)}
							calendarContainer={(props) =>
								CalendarContainer({
									...props,
									options: [
										{
											dates: [getDaysFromToday(-14), getToday()],
											title: 'Last 14 days',
										},
										{
											dates: [getDaysFromToday(-30), getToday()],
											title: 'Last 30 days',
										},
										{
											dates: getLastSixMonthsRange(),
											title: 'Last 6 months',
										},
										{
											dates: getLastYearRange(),
											title: 'Last year',
										},
										{
											dates: getCurrentYearDateRange(),
											title: 'Current year',
										},
										{
											dates: getCurrentMonthRange(),
											title: 'Current month',
										},
									],
									setDates: setInternalDateValues,
									children: props.children,
								})
							}
							renderCustomHeader={CalendarHeader}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

/** Check if two nullable dates are equal */
const areNullableDatesEqual = (first: Date | null, second: Date | null) => {
	// If both types are null, they are equal
	if (first === null && second === null) {
		return true;
	}

	// If both types are dates and their timestamps are the same, they are equal
	if (
		first instanceof Date &&
		second instanceof Date &&
		first.getTime() === second.getTime()
	) {
		return true;
	}

	// For all other paths, they are not equal
	return false;
};

/** Get the date range for the current year */
const getCurrentYearDateRange = (): [Date, Date] => {
	const currentDate = getToday();

	const startDate = new Date(currentDate.getFullYear(), 0, 1);

	return [startDate, currentDate];
};

const getCurrentMonthRange = (): [Date, Date] => {
	const currentDate = getToday();

	const startOfMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		1
	);

	return [startOfMonth, currentDate];
};

const getLastYearRange = (): [Date, Date] => {
	const currentDate = getToday();

	const startOfLastYear = new Date(currentDate.getFullYear() - 1, 0, 1);

	const endOfLastYear = new Date(currentDate.getFullYear() - 1, 11, 31);

	return [startOfLastYear, endOfLastYear];
};

const getLastSixMonthsRange = (): [Date, Date] => {
	const currentDate = getToday();

	const startOfLastSixMonths = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() - 6,
		1
	);

	return [startOfLastSixMonths, currentDate];
};
