import './Explorer.scss';
import { Canvas } from '@react-three/fiber';
import { Stage } from './objects/Stage';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { isDefined } from '../../Core/utils/Logic';
import { Suspense, useEffect } from 'react';
import { ModelExplorer } from './objects/ModelExplorer';
import { SoilLevel } from './objects/SoilLevel';
import { WaterAnimated } from './objects/Water';
import { useSectionSelectorData } from './useSectionSelectorData';
import { TitleBarMenu } from '../../Core/components/TitleBarMenu/TitleBarMenu';
import { useSectionMenu } from './useSectionMenuItems';
import { TitleBar } from '../../Core/components/TitleBar/TitleBar';
import { ExplorerNavigation } from './ExplorerNavigation';
import { useAnalysisExplorerData } from './useAnalysisExplorerData';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import { setSelectedSensor } from './Explorer.slice';
import { noop } from '../../Core/utils/Function';
import {
	setSeaElevation,
	toggleExplorerSettings,
} from './ExplorerSettings/Explorer.Settings.slice';
import { ViewCube } from './objects/ViewCube/ViewCube';
import { Provider } from 'react-redux';
import { store } from '../../Core/redux/store';
import { useDefaultCameraPosition } from './objects/ViewCube/state/state';
import { ResetView } from './objects/ViewCube/ResetView/ResetView';
import { LegendInformation } from '../AnalysisExplorer/LegendInformation/LegendInformation';

export const Explorer = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const location = useLocation();

	// Get siteId from URL params
	const { siteId } = useParams<{
		siteId: string;
	}>();

	const sensorLocations = useAppSelector(
		(state) => state.explorer.sensorLocations
	);

	const selectedSensorId = useAppSelector(
		(state) => state.explorer.selectedSensorId
	);

	const isSeaEnabled = useAppSelector(
		(state) => state.explorerSettings.seaEnabled
	);

	const isSoilEnabled = useAppSelector(
		(state) => state.explorerSettings.soilEnabled
	);

	const seaElevation = useAppSelector(
		(state) => state.explorerSettings.seaElevation
	);

	const isViewCubeEnabled = useAppSelector(
		(state) => state.explorerSettings.viewCubeEnabled
	);

	const isLegendInformationEnabled = useAppSelector(
		(state) => state.explorerSettings.displayLegendInformation
	);

	const dispatchSelectedSensorId = (id: string | null) =>
		dispatch(setSelectedSensor(id));

	const getSectionId = () => {
		const splitPath = location.pathname.split('/');
		if (splitPath.at(-1) === 'analysis') {
			return splitPath.at(-2);
		}

		if (['explorer', 'dashboard', 'trends'].includes(splitPath.at(-1) ?? '')) {
			return undefined;
		}

		return splitPath.at(-1);
	};

	const sectionId = getSectionId();

	const handleSectionSelect = (id: string) => {
		navigate(`explorer/${id}`);
	};

	const defaultCameraPosition = useDefaultCameraPosition();

	const isExplorer = location.pathname.includes('explorer');
	const isDashboard = location.pathname.includes('dashboard');
	const isTrends = location.pathname.includes('trends');

	const data = useSectionSelectorData({
		siteId: siteId ?? '',
		onSectionSelect:
			!sectionId && !isDashboard && !isTrends ? handleSectionSelect : undefined,
	});

	const analysisData = useAnalysisExplorerData({ sectionId: sectionId ?? '' });

	// Get data for the section menu
	const sectionMenu = useSectionMenu();

	/**
	 * Toggle the Analysis Explorer panel
	 */

	const lastChosenSectionId = useAppSelector(
		(state) => state.explorer.lastChosenSectionId
	);

	function handleToggleAnalysisExplorer() {
		const pathSegments = location.pathname.split('/');
		const lastSegment = pathSegments.pop() || pathSegments.pop(); // Safely handle trailing slashes https://stackoverflow.com/questions/4758103/last-segment-of-url-with-javascript

		if (lastSegment === 'analysis') {
			navigate(location.pathname + '/..');
			return;
		} else {
			navigate(`explorer/${lastChosenSectionId}/analysis`);
		}
	}

	function handleToggleExplorerSettings() {
		dispatch(toggleExplorerSettings());
	}

	useEffect(() => {
		if (
			(!sectionId || isDashboard || isTrends) &&
			data !== 'error' &&
			data !== 'loading' &&
			seaElevation === data.seaLevel
		) {
			dispatch(setSeaElevation(data.seaLevel));
		}
	}, [data, dispatch, sectionId, isDashboard, isTrends, seaElevation]);

	return (
		<div className="Explorer">
			<div className="Explorer__Model" id="Explorer__Portal">
				{sectionMenu.isLoaded && isExplorer && (
					<div className="Explorer__SectionMenu">
						<TitleBarMenu
							theme="Overlay"
							items={sectionMenu.items}
							onSelectItem={sectionMenu.onSelectItem}
						>
							{sectionMenu.currentSelection}
						</TitleBarMenu>
					</div>
				)}

				{sectionMenu.isLoaded && !isExplorer && (
					<div className="Dashboard__SiteTitle">
						<TitleBar theme="Overlay">{sectionMenu.currentSelection}</TitleBar>
					</div>
				)}

				<Canvas
					gl={{
						logarithmicDepthBuffer: true,
					}}
					shadows
				>
					{/* For Redux to work inside the Canvas it needs its own provider because the THREE namespace seems to be seperated?*/}
					<Provider store={store}>
						<Suspense fallback={<></>}>
							{(!sectionId || isDashboard || isTrends) &&
							data !== 'error' &&
							data !== 'loading' ? (
								<>
									<ModelExplorer
										{...data.modelData}
										sensorLocations={sensorLocations}
										selectedSensorId={selectedSensorId}
										setSelectedSensorId={dispatchSelectedSensorId}
									/>

									{isDefined(data.soilLevel) && isSoilEnabled && (
										<SoilLevel level={data.soilLevel} />
									)}

									{isDefined(data.seaLevel) && isSeaEnabled && (
										<WaterAnimated level={seaElevation} />
									)}
								</>
							) : (
								analysisData !== 'error' &&
								analysisData !== 'loading' && (
									<>
										<ModelExplorer
											{...analysisData.modelData}
											selectedSensorId={null}
											setSelectedSensorId={noop}
										/>

										{isDefined(analysisData.soilLevel) && isSoilEnabled && (
											<SoilLevel level={analysisData.soilLevel} />
										)}

										{isDefined(analysisData.seaLevel) && isSeaEnabled && (
											<WaterAnimated level={seaElevation} />
										)}
									</>
								)
							)}

							<Stage />

							<PerspectiveCamera
								makeDefault
								position={[
									defaultCameraPosition[0],
									defaultCameraPosition[1],
									defaultCameraPosition[2],
								]}
								near={5}
								far={100000}
							/>
							<OrbitControls
								enableDamping
								makeDefault //Enable this to make the controls the default controls
								dampingFactor={0.1}
								rotateSpeed={0.5}
								zoomSpeed={1.5}
							/>

							{isViewCubeEnabled &&
								analysisData !== 'error' &&
								analysisData !== 'loading' &&
								data !== 'error' &&
								data !== 'loading' && <ViewCube />}
						</Suspense>
					</Provider>
				</Canvas>
				{isLegendInformationEnabled && sectionId && (
					<div className={`Explorer__LegendInformation`}>
						<LegendInformation sectionId={sectionId} />
					</div>
				)}

				{isViewCubeEnabled &&
					analysisData !== 'error' &&
					analysisData !== 'loading' &&
					data !== 'error' &&
					data !== 'loading' && <ResetView />}

				{
					<div className="Explorer__Navigation">
						<ExplorerNavigation
							onToggleExplorerSettings={handleToggleExplorerSettings}
							onToggleAnalysisExplorer={handleToggleAnalysisExplorer}
						/>
					</div>
				}
			</div>
		</div>
	);
};
