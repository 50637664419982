import { useMemo } from 'react';
import { useNavigate, useMatch, Navigate } from 'react-router';
import { MenuButton } from '../Core/components/MenuButton/MenuButton';
import { Route, Routes } from 'react-router-dom';

import { SideMenu, SideMenuTheme } from '../Core/components/SideMenu/SideMenu';
import './RIAMS.scss';
import classNames from 'classnames';
import { ElementDetailsOverlayContainer } from './ElementDetails/ElementDetailsOverlay/ElementDetailsOverlay.container';
import { Admin } from './Admin/Admin';
import { useGetUserQuery } from '../Core/Api';
import { Trends } from './Trends/Trends';
import { Dashboard } from './Dashboard/Dashboard';
import { Fullscreener } from '../Core/components/Fullscreener/Fullscreener';
import { AnalysisExplorerContainer } from './AnalysisExplorer/AnalysisExplorer.container';
import { useQueryParam } from '../Core/hooks/useQueryParam';
import { Explorer } from './Explorer/Explorer';
import { ExplorerSettings } from './Explorer/ExplorerSettings/ExplorerSettings';
import { useAppSelector } from '../Core/redux/useAppSelector';
import { useAppDispatch } from '../Core/redux/useAppDispatch';
import { toggleExplorerSettings } from './Explorer/ExplorerSettings/Explorer.Settings.slice';

export const RIAMS = () => {
	const { data: userData } = useGetUserQuery({});

	// Get the history object for navigation
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const [fullscreen] = useQueryParam('fullscreen');

	const riamsRoute = useMatch({
		path: '/riams/:siteId/*',
		end: false,
	});

	const { siteId = '' } = riamsRoute?.params ?? {};

	// Determine if UI should be rendered for the explorer
	const isExplorerPage = useMatch('/riams/:siteId/explorer/*') !== null;
	const isDashboardPage = useMatch('/riams/:siteId/dashboard/*') !== null;
	const isFullScreenExplorer = isExplorerPage && fullscreen === 'true';
	const isFullScreenDashboard = isDashboardPage && fullscreen === 'true';
	const isTrendsPage = useMatch('/riams/:siteId/trends/*') !== null;
	const isFullScreenTrends = isTrendsPage && fullscreen === 'true';

	const shouldShowSideMenyAsOverlay = useMemo(() => {
		return (
			(isExplorerPage && !isFullScreenExplorer) ||
			(isDashboardPage && !isFullScreenDashboard) ||
			(isTrendsPage && !isFullScreenTrends)
		);
	}, [
		isDashboardPage,
		isExplorerPage,
		isFullScreenDashboard,
		isFullScreenTrends,
		isTrendsPage,
		isFullScreenExplorer,
	]);

	// Get the sidebar theme to show
	const sidebarTheme: SideMenuTheme = useMemo(
		() =>
			shouldShowSideMenyAsOverlay
				? SideMenuTheme.OVERLAY
				: SideMenuTheme.SIDEBAR,
		[shouldShowSideMenyAsOverlay]
	);

	const isSuperAdmin =
		userData?.isLoggedIn && userData.user.group === 'Superadmin';

	const superadminMenuItems = [
		{
			iconName: 'Gear',
			routeLink: `/riams/admin`,
			routeMatcher: '/riams/admin/*',
			isDisabled: false,
		},
	];

	// Configure the menu items
	const menuItems = [
		{
			iconName: 'Cube',
			routeLink: `/riams/${siteId}/explorer`,
			routeMatcher: '/riams/:siteId/explorer/*',
			isDisabled: false,
		},
		{
			iconName: 'DashboardNav',
			routeLink: `/riams/${siteId}/dashboard`,
			routeMatcher: '/riams/:siteId/dashboard/*',
			isDisabled: false,
		},
		{
			iconName: 'TrendsNav',
			routeLink: `/riams/${siteId}/trends`,
			routeMatcher: '/riams/:siteId/trends/*',
			isDisabled: false,
		},
	];

	// If we are not in production, add the "fake" Inspections button
	if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
		menuItems.push({
			iconName: 'InspectionsNav',
			routeLink: `/riams/${siteId}/inspections`,
			routeMatcher: `/riams/${siteId}/inspections`,
			isDisabled: true,
		});
	}

	// User click handler
	function onUserClick() {
		// navigate('/profile');
	}

	// Logo click handler
	function onLogoClick() {
		navigate('/sites');
	}

	// Click handler to route to the correct route
	function onMenuClick(routeLink: string) {
		navigate(routeLink);
	}

	const showExplorerSettings = useAppSelector(
		(state) => state.explorerSettings.explorerSettings
	)

	return (
		<section
			className={classNames('RIAMS', {
				'has-sidemenuOverlay': shouldShowSideMenyAsOverlay,
			})}
		>
			<nav className="RIAMS__Menu">
				<SideMenu
					theme={sidebarTheme}
					onLogoClick={onLogoClick}
					onUserClick={onUserClick}
				>
					{!isSuperAdmin &&
						menuItems.map((item) => (
							<MenuButton
								key={item.iconName}
								iconName={item.iconName}
								onClick={() => onMenuClick(item.routeLink)}
								routeMatcher={item.routeMatcher}
								isDisabled={item.isDisabled}
							/>
						))}
					{isSuperAdmin &&
						superadminMenuItems.map((item) => (
							<MenuButton
								key={item.iconName}
								iconName={item.iconName}
								onClick={() => onMenuClick(item.routeLink)}
								routeMatcher={item.routeMatcher}
								isDisabled={item.isDisabled}
							/>
						))}
				</SideMenu>
			</nav>
			<main className="RIAMS__Main">
				{showExplorerSettings && (
					<ExplorerSettings onClose={() => dispatch(toggleExplorerSettings())} />

				)}
				<Fullscreener
					leftElement={
						<Routes>
							<Route
								path=":siteId/*"
								element={
									<div className="RIAMS__Model">
										<Explorer />
									</div>
								}
							/>
							<Route path="admin/*" element={null} />
							<Route path="admin/*" element={null} />
						</Routes>
					}
					rightElement={
						<Routes>
							<Route path=":siteId" element={<Navigate to="explorer" />} />
							<Route
								path=":siteId/explorer/:sectionId/analysis"
								element={<AnalysisExplorerContainer />}
							/>
							<Route path=":siteId/trends/*" element={<Trends />} />
							<Route path=":siteId/dashboard/*" element={<Dashboard />} />
							<Route path="admin/*" element={<Admin />} />
						</Routes>
					}
				/>
			</main>
			<ElementDetailsOverlayContainer />
		</section>
	);
};
