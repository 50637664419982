import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
	Mesh,
	Camera,
	Shape,
	ShapeGeometry,
	MeshPhongMaterial,
	DoubleSide,
	RingGeometry,
} from 'three';
import { useCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { animate, AnimationPlaybackControls } from 'framer-motion';
import { Vector3 } from 'three';
import { createTextureFromText } from '../utils/CreateTextureFromText';
import { useViewCubeFunctions } from '../hooks/useViewCubeFunctions';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { setCubeHover, useCubeHover } from '../state/state';
import { useAppDispatch } from '../../../../../Core/redux/useAppDispatch';
import { configuration } from '../../../../../Core/configuration/configuration';

interface LoadedShapeCache {
	[key: string]: Shape[][];
}

// type  for the compas slice component.
type CompasSliceComponentProps = {
	cardinalDirection: string;
	ringPosition: [number, number, number];
	ringRotation: [number, number, number];
	thetaStart: number;
	textPosition: [number, number, number];
	textRotation: [number, number, number];
	direction: number;
	opacity: number;
	fadeInDuration: number;
	fadeOutDuration: number;
	vbCamera: React.MutableRefObject<Camera>;
	targetCameraPosition: React.MutableRefObject<Vector3 | undefined>;
	loadedShapeCache: LoadedShapeCache;
	cubeSize: number;
};

// Component for the compas slice. Uses ringGeometry.
const CompasSliceComponent: React.FC<CompasSliceComponentProps> = ({
	cardinalDirection,
	ringPosition,
	ringRotation,
	thetaStart,
	textPosition,
	textRotation,
	direction,
	opacity,
	fadeInDuration,
	fadeOutDuration,
	vbCamera,
	targetCameraPosition,
	loadedShapeCache,
	cubeSize,
}: CompasSliceComponentProps) => {
	const controls = useThree((state) => state.controls) as OrbitControlsImpl;
	const { camera } = useThree();
	const ringRef = useRef<Mesh>(null!); // Ref for the ring.
	const [sectionHover, setSectionHover] = useState<boolean>(false); // State for if the section is hovered.
	const cubeHover = useCubeHover();
	const dispatch = useAppDispatch();
	const useVbCamera = useCamera(vbCamera);
	const { handlePointerOut, handlePointerMove } = useViewCubeFunctions(
		camera,
		controls,
		targetCameraPosition
	);

	// This function handles the rotation of the camera when the compas is clicked.
	const rotateCameraForCompass = useCallback(
		(rad: number) => {
			// new vector from current position and the radians of the
			const radius: number = camera.position.distanceTo(
				new Vector3(controls?.target.x, camera.position.y, controls?.target.z)
			);

			// find the current angle of the camera relative to the radius from camera to pivot point.
			let startRad: number = Math.acos(
				(camera.position.z - controls.target.z) / radius
			);
			// Finds the rad it needs to up at. If the rad is negative, it will be the max of the two. If the rad is positive, it will be the min of the two.
			let endRad: number =
				rad < 0 ? Math.max(rad, -Math.PI) : Math.min(rad, Math.PI);
			// If the camera is on the left side of the pivot point, the angle is negative.
			if (camera.position.x < controls.target.x) {
				startRad = startRad * -1;
			}
			// If the camera is on the right side of the pivot point relative to the startRad, the angle is positive.
			else if (camera.position.x > controls.target.x && startRad < 0) {
				startRad = startRad * -1;
			}

			// Calculate the shortest rotation direction
			const angleDiff = endRad - startRad;
			if (angleDiff > Math.PI) {
				endRad -= 2 * Math.PI;
			} else if (angleDiff < -Math.PI) {
				endRad += 2 * Math.PI;
			}

			// Get the current y position of the camera before animation start, so we force it to stick. OrbitControls for some reason makes it a bit wobbly without.
			const stickY: number = camera.position.y;

			// Animate the camera to the new angle.
			animate(startRad, endRad, {
				duration: 1,
				onUpdate: (rad: number) => {
					camera.position.z = radius * Math.cos(rad) + controls.target.z;
					camera.position.x = radius * Math.sin(rad) + controls.target.x;
					camera.position.y = stickY;
					camera.updateProjectionMatrix();
				},
			});
		},
		[camera, controls]
	);

	const animationControls = useRef<AnimationPlaybackControls>(); // Used to stop the animation when the cube is no longer hovered
	// Fade in and out animation.
	useEffect(() => {
		const material = ringRef.current.material as MeshPhongMaterial;

		// Fade in
		if (material && cubeHover) {
			animationControls.current && animationControls.current.stop(); // To avoid hover bug
			animationControls.current = animate(opacity + 0.1, 1, {
				duration: fadeInDuration,
				onUpdate: (value: number) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
		}

		// Fade out
		else if (material && !cubeHover) {
			animationControls.current && animationControls.current.stop(); // To avoid hover bug
			animationControls.current = animate(1, opacity + 0.1, {
				duration: fadeOutDuration,
				onUpdate: (value: number) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
			setSectionHover(false);
		}
	}, [cubeHover, fadeInDuration, fadeOutDuration, opacity]);

	const svgMeshRef = useRef<Mesh>();

	const loadSVGIntoMesh = useCallback(
		(shape: Shape[][]) => {
			if (shape?.length) {
				const shapes = shape;

				const shapeGeometry = new ShapeGeometry(shapes[0]);

				const mesh = new Mesh(
					shapeGeometry,
					new MeshPhongMaterial({
						side: DoubleSide,
						transparent: true,
						opacity: cubeHover ? 1 : 0.8,
						color: cubeHover
							? sectionHover
								? configuration.viewCube.textHover
								: configuration.viewCube.textActive
							: configuration.viewCube.textIdle,
					})
				);

				mesh.scale.set(
					(cubeSize * 1.5) / 100,
					(cubeSize * 1.5) / 100,
					(cubeSize * 1.5) / 100
				);
				mesh.rotation.set(...textRotation);
				mesh.position.set(...textPosition);

				// Remove the previous mesh if it exists
				if (svgMeshRef.current) {
					ringRef.current?.remove(svgMeshRef.current);
				}

				// Update the svgMeshRef with the new mesh
				svgMeshRef.current = mesh;

				ringRef.current?.add(mesh);
			}
		},
		[cubeHover, sectionHover, cubeSize, textRotation, textPosition]
	);

	// This code is used to render the SVGs that are used in the app. It is called on each SVG to be rendered, and each SVG is rendered in a different manner depending on the cardinal direction. The SVGs are cached in the loadedShapeCache object that is passed to the function. The code is executed on every render of the component, but the SVGs are only loaded once.
	useEffect(() => {
		switch (cardinalDirection) {
			case 'N':
				loadSVGIntoMesh(loadedShapeCache.N);
				break;
			case 'S':
				loadSVGIntoMesh(loadedShapeCache.S);
				break;
			case 'E':
				loadSVGIntoMesh(loadedShapeCache.E);
				break;
			case 'W':
				loadSVGIntoMesh(loadedShapeCache.W);
				break;
		}
	}, [
		sectionHover,
		cardinalDirection,
		textPosition,
		textRotation,
		loadSVGIntoMesh,
		loadedShapeCache.N,
		loadedShapeCache.S,
		loadedShapeCache.E,
		loadedShapeCache.W,
	]);

	return (
		<>
			<mesh
				ref={ringRef}
				raycast={useVbCamera}
				position={ringPosition}
				rotation={ringRotation}
				onPointerOut={(e) => handlePointerOut(e, setSectionHover)}
				onPointerMove={(e) => {
					handlePointerMove(e, setSectionHover);
				}}
				onClick={() => {
					rotateCameraForCompass(direction);
					// https://github.com/pmndrs/react-three-fiber/issues/2243
					// Continous hover for moving object not possible. Would be too expensive to calculate each frame
					// Possible solution could be spatial queries https://github.com/gkjohnson/three-mesh-bvh
					setSectionHover(false);
					// Ensures the CubeHover to stay Active. - Will bug out if not.
					dispatch(setCubeHover(true));
				}}
			>
				<ringGeometry
					args={[
						cubeSize * 0.9,
						cubeSize * 1.15,
						32,
						1,
						thetaStart,
						Math.PI / 2,
					]}
				/>
				<meshPhongMaterial
					map={createTextureFromText({ sectionHover, cubeHover })}
					transparent={true}
					side={DoubleSide}
				/>

				{/* Outline for the ring slices/sections */}
				<group>
					<lineSegments>
						<edgesGeometry
							args={[
								new RingGeometry(
									cubeSize * 0.9,
									cubeSize * 1.15,
									32,
									1,
									thetaStart,
									Math.PI / 2
								),
							]}
						/>
						<lineBasicMaterial
							color={
								cubeHover
									? configuration.viewCube.lineHover
									: configuration.viewCube.lineIdle
							}
						/>
					</lineSegments>
				</group>
			</mesh>
		</>
	);
};

export default CompasSliceComponent;
