import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
	{
		path: '*',
		element: <App />,
	},
]);

const container = document.getElementById('appRoot');

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
