import { OrthographicCamera, useCamera } from '@react-three/drei';
import { useFrame, useThree, createPortal } from '@react-three/fiber';
import {
	Scene,
	Matrix4,
	Vector3,
	Vector2,
	Raycaster,
	BoxGeometry,
	Shape,
	InstancedMesh,
	LineSegments,
} from 'three';
import { useRef, useMemo, useCallback, useEffect } from 'react';
import { animate, AnimationPlaybackControls } from 'framer-motion';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import northSVG from './SVG/N.svg';
import southSVG from './SVG/S.svg';
import eastSVG from './SVG/E.svg';
import westSVG from './SVG/W.svg';
import { useViewCubeFunctions } from './hooks/useViewCubeFunctions';
import PlaneComponent from './components/PlaneComponent';
import BoxComponent from './components/EdgeAndCornerComponent';
import CompasSliceComponent from './components/CompasSliceComponent';
import {
	setCubeHover,
	setIsCubePressed,
	setSectionActive,
	useCubeHover,
	useIsCubePressed,
	useSectionActive,
	useDefaultCameraPosition,
	useResetView,
	setResetView,
} from './state/state';
import { useAppDispatch } from '../../../../Core/redux/useAppDispatch';
import { useAppSelector } from '../../../../Core/redux/useAppSelector';
import { configuration } from '../../../../Core/configuration/configuration';

// Position and orientation of the planes
type PlaneData = {
	name: string;
	position: [number, number, number];
	rotation: [number, number, number];
	size: [number, number];
};

// Position and orientation of the edges and corners
type EdgeAndCornerData = {
	position: [number, number, number];
	size: [number, number, number];
};

// type  for the compas data
type CompasData = {
	cardinalDirection: string;
	position: [number, number, number];
	rotation: [number, number, number];
	thetaStart: number;
	textPosition: [number, number, number];
	textRotation: [number, number, number];
	direction: number;
};

type CompasDefaultValues = {
	position: [number, number, number];
	rotation: [number, number, number];
};

//Loads and caches the SVGs for the viewcube
function loadSvgShapes() {
	const loadedShapeCache: LoadedShapeCache = {
		N: [],
		S: [],
		E: [],
		W: [],
	};
	const svgLoaderCache = {
		N: new SVGLoader().load(northSVG, (data: { paths: any[] }) => {
			loadedShapeCache.N = data.paths.map(
				(path: { toShapes: (arg0: boolean) => any }) => path.toShapes(true)
			);
		}),
		S: new SVGLoader().load(southSVG, (data: { paths: any[] }) => {
			loadedShapeCache.S = data.paths.map(
				(path: { toShapes: (arg0: boolean) => any }) => path.toShapes(true)
			);
		}),
		E: new SVGLoader().load(eastSVG, (data: { paths: any[] }) => {
			loadedShapeCache.E = data.paths.map(
				(path: { toShapes: (arg0: boolean) => any }) => path.toShapes(true)
			);
		}),
		W: new SVGLoader().load(westSVG, (data: { paths: any[] }) => {
			loadedShapeCache.W = data.paths.map(
				(path: { toShapes: (arg0: boolean) => any }) => path.toShapes(true)
			);
		}),
	};
	return { svgLoaderCache, loadedShapeCache };
}

const { loadedShapeCache } = loadSvgShapes();

interface LoadedShapeCache {
	[key: string]: Shape[][];
}

export const ViewCube = () => {
	// > Scene Variables <
	const { gl, camera, size } = useThree(); // Used to get the current camera and size of the canvas
	const vbScene = useMemo(() => new Scene(), []); // Scene used to render the view cube
	const vbCamera = useRef<any>(camera); // Virtual camera used to render the view cube
	const ref = useRef<InstancedMesh>(null); // The cube itself
	const hoverMesh = useRef<InstancedMesh>(null); // mesh used to detect mouseover.
	const outlineRef = useRef<LineSegments>(null); // The outline of the cube
	const matrix = new Matrix4(); // Used to calculate the position of the cube
	const controls = useThree((state) => state.controls) as OrbitControlsImpl; // Used to get the current camera position relative to the pivot Point. Requires the OrbitControls in /explorer to have MakeDefault attribute set.
	const useVbCamera = useCamera(vbCamera); // Used to set the camera to the virtual camera
	// > Cube & Compas (related) Variables <

	// SectionActive disables some interactions when dragging the camera when it is false. This is used to prevent certain unwanted interactions like mouseover cube interactions when dragging the camera.
	const dispatch = useAppDispatch();
	const sectionActive = useSectionActive();

	// These sets the default size of the cube and the hover state of the cube. The hover state is used to change the size of the cube when the cube is hovered and not hovered.

	// These sets the size of the cube and the hover state of the cube. The hover state is used to change the size of the cube when the cube is hovered and not hovered.
	const cubeSize: number = useAppSelector(
		(state) => state.explorerSettings.viewCubeSize
	).value;
	const cubeHover = useCubeHover();
	const isCubePressed = useIsCubePressed();

	// These sets the duration of the animations and the base opacity. The opacity is used to fade in and out the edges and corners of the cube when the cube is hovered and not hovered.
	const opacity = 0.35;
	const fadeInDuration = 0;
	const fadeOutDuration = 0.35;
	const zoomInDuration = 0.25;
	const zoomOutDuration = 0.35;

	//These sets the size of the edges and corners and the size of the planes relative to the cube size
	const planeSizeFactor = 0.7;
	const edgeAndCornerFactor = 0.15;

	// Controls true north - Set the vector value for desired north as a 2D vector from origo. - Default is 0,0
	const compasNorth: Vector2 = useMemo(() => new Vector2(0, 0), []);

	// Used to calculate the rotation of the compas - Needed to calculate around π to -π
	const rotationValue = useCallback(() => {
		if (compasNorth.x === 0 && compasNorth.y === 0) {
			return 0;
		} else if (compasNorth.x === 0) {
			return compasNorth.y > 0 ? -Math.PI / 2 : Math.PI / 2;
		}
		return Math.atan2(compasNorth.x, compasNorth.y);
	}, [compasNorth]);

	// Normalize direction to be between -PI and PI to avoid limit collision when the compas is rotated
	const normalizeDirection = useCallback((direction: number): number => {
		if (direction > Math.PI) {
			return normalizeDirection(direction - 2 * Math.PI);
		} else if (direction <= -Math.PI) {
			return normalizeDirection(direction + 2 * Math.PI);
		}
		return direction;
	}, []);

	// Default values for the compas - Used to set the position and rotation of the compas
	// Used to describe the position relative to the view cube itself for the parent mesh of the individual compas sections. The parent mesh is used to rotate the compas sections.
	const compasDefaultValues: CompasDefaultValues = {
		position: [0, -cubeSize / 2, 0],
		rotation: [0, Math.PI / 4 - rotationValue(), 0],
	};

	// ------------------

	//Describes the planes of the cube
	const planes = useMemo<PlaneData[]>(() => {
		const planeData: PlaneData[] = [
			{
				name: 'RIGHT',
				position: [cubeSize / 2, 0, 0],
				rotation: [0, Math.PI / 2, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
			{
				name: 'LEFT',
				position: [-cubeSize / 2, 0, 0],
				rotation: [0, -Math.PI / 2, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
			{
				name: 'TOP',
				position: [0, cubeSize / 2, 0],
				rotation: [-Math.PI / 2, 0, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
			{
				name: 'BOTTOM',
				position: [0, -cubeSize / 2, 0],
				rotation: [Math.PI / 2, 0, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
			{
				name: 'FRONT',
				position: [0, 0, cubeSize / 2],
				rotation: [0, 0, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
			{
				name: 'BACK',
				position: [0, 0, -cubeSize / 2],
				rotation: [0, Math.PI, 0],
				size: [cubeSize * planeSizeFactor, cubeSize * planeSizeFactor],
			},
		];

		return planeData;
	}, [cubeSize, planeSizeFactor]);

	// Used to create the edges and corners of the cube
	const createEdgeAndCorner = (
		position: [number, number, number],
		size: [number, number, number]
	): EdgeAndCornerData => ({ position, size });

	// Both -1 and 1 is considered true, and 0 false. Can differ from language to language.
	const edgeAndCorners = useMemo(() => {
		const edgeAndCornerData: EdgeAndCornerData[] = [
			[1, 1, 1],
			[1, 1, -1],
			[1, -1, 1],
			[1, -1, -1],
			[-1, 1, 1],
			[-1, 1, -1],
			[-1, -1, 1],
			[-1, -1, -1],
			[0, 1, 1],
			[0, 1, -1],
			[0, -1, 1],
			[0, -1, -1],
			[1, 0, 1],
			[1, 0, -1],
			[-1, 0, 1],
			[-1, 0, -1],
			[1, 1, 0],
			[1, -1, 0],
			[-1, 1, 0],
			[-1, -1, 0],
		].map(([x, y, z]) =>
			createEdgeAndCorner(
				[(x * cubeSize) / 2.35, (y * cubeSize) / 2.35, (z * cubeSize) / 2.35],
				[
					cubeSize * (x ? edgeAndCornerFactor : planeSizeFactor),
					cubeSize * (y ? edgeAndCornerFactor : planeSizeFactor),
					cubeSize * (z ? edgeAndCornerFactor : planeSizeFactor),
				]
			)
		);

		return edgeAndCornerData;
	}, [cubeSize, edgeAndCornerFactor]);

	// Used to create the compas data
	const compas = useMemo(() => {
		const compasData: CompasData[] = [
			// Create 4 cardinal directions (N, E, S, W) facing down
			{
				cardinalDirection: 'N',
				position: [0, 0, 0],
				rotation: [-Math.PI / 2, 0, 0],
				thetaStart: 0,
				textPosition: [(cubeSize / 2) * 1.43, (cubeSize / 2) * 1.235, 1],
				textRotation: [0, Math.PI, Math.PI / 4],
				direction: normalizeDirection(0 - rotationValue()),
			},
			{
				cardinalDirection: 'W',
				position: [0, 0, 0],
				rotation: [-Math.PI / 2, 0, 0],
				thetaStart: Math.PI / 2,
				textPosition: [-(cubeSize / 2) * 1.72, (cubeSize / 2) * 1.385, 1],
				textRotation: [0, Math.PI, (3 * Math.PI) / 4],
				direction: normalizeDirection(Math.PI / 2 - rotationValue()),
			},
			{
				cardinalDirection: 'S',
				position: [0, 0, 0],
				rotation: [-Math.PI / 2, 0, 0],
				thetaStart: Math.PI,
				textPosition: [-(cubeSize / 2) * 1.46, -(cubeSize / 2) * 1.685, 1],
				textRotation: [0, Math.PI, Math.PI / 4],
				direction: normalizeDirection(-Math.PI - rotationValue()),
			},
			{
				cardinalDirection: 'E',
				position: [0, 0, 0],
				rotation: [-Math.PI / 2, 0, 0],
				thetaStart: (3 * Math.PI) / 2,
				textPosition: [(cubeSize / 2) * 1.65, -(cubeSize / 2) * 1.485, 1],
				textRotation: [0, Math.PI, (7 * Math.PI) / 4],
				direction: normalizeDirection(-Math.PI / 2 - rotationValue()),
			},
		];

		return compasData;
	}, [cubeSize, rotationValue, normalizeDirection]);

	//Handles hover zoom animationa
	const hoverReached = useRef<number>(cubeSize); // Used to keep track of the current size of the cube when hovering
	const animationControls = useRef<AnimationPlaybackControls>(); // Used to stop the animation when the cube is no longer hovered

	useEffect(() => {
		const animateZoom = (
			startSize: number,
			endSize: number,
			duration: number
		) => {
			animationControls.current && animationControls.current.stop(); // Needed to avoid bug where noHover states get overwritten.
			animationControls.current = animate(startSize, endSize, {
				duration,
				onUpdate: (value: number) => {
					const scale = value / cubeSize;
					if (ref.current) {
						ref.current.scale.set(scale, scale, scale);
					}
					hoverReached.current = value;
				},
			});
		};
		if (sectionActive) {
			if (cubeHover) {
				// If the cube is hovered, zoom in
				animateZoom(hoverReached.current, cubeSize * 1.5, zoomInDuration);
			} else {
				// If the cube is not hovered, zoom out
				animateZoom(hoverReached.current, cubeSize, zoomOutDuration);
			}
		}
	}, [cubeHover, sectionActive, cubeSize]);

	// Target position for the camera when a plane or edge/corner is pressed
	const targetCameraPosition = useRef<Vector3>();

	// This is the camera that will pan when isCubePressed is true.
	// Currently using the lerp function. Should Ideally be a quarternion slerp.
	useFrame(() => {
		if (isCubePressed && targetCameraPosition.current) {
			const cameraPosition: Vector3 = camera.position;
			const targetPosition: Vector3 = targetCameraPosition.current;
			const distanceToTarget: number =
				cameraPosition.distanceTo(targetPosition);

			if (distanceToTarget < 5) {
				dispatch(setIsCubePressed(false));
			} else {
				cameraPosition.lerp(targetPosition, 0.05);
				camera.updateProjectionMatrix();
			}
		}
	});

	// This is the camera that will rotate when the cube is hovered.
	// Keeps the rotation of the cube relative to the rotation of the orbitcontrols.
	useFrame(() => {
		// View cube rotation
		matrix.copy(camera.matrix).invert();
		if (ref.current) {
			ref.current.quaternion.setFromRotationMatrix(matrix);
		}

		// View cube render code
		gl.autoClear = false;
		gl.setPixelRatio(window.devicePixelRatio);
		gl.clearDepth();
		gl.render(vbScene, vbCamera.current);
		gl.getContext().getExtension('EXT_texture_filter_anisotropic');
		gl.getContext().canvas.addEventListener('webglcontextrestored', () => {
			gl.getContext().getExtension('EXT_texture_filter_anisotropic');
		});
		gl.getContext().canvas.addEventListener('webglcontextlost', () => {
			console.log('Context Lost.');
		});
	}, 1);

	// Handles the view cube functions
	const { handlePointerEnterHoverArea, handlePointerLeaveHoverArea } =
		useViewCubeFunctions(camera, controls, targetCameraPosition);

	// Mousedown event handler for clicks.
	const handleCanvasClicks: HTMLCanvasElement =
		document.getElementsByTagName('canvas')[0];

	const raycaster = new Raycaster();
	const mouse = new Vector2();

	// Used to disable view cube 'in progress' camera panning when zooming.
	handleCanvasClicks?.addEventListener('wheel', () => {
		if (isCubePressed) {
			dispatch(setIsCubePressed(false));
		}
	});

	// State to trigger the camera pan animation
	const resetViewState = useResetView();

	const defaultCameraPositionObj = useDefaultCameraPosition();

	// Resets the camera to the default position
	const resetView = useCallback(() => {
		dispatch(setIsCubePressed(false));
		camera.lookAt(0, 0, 0);
		controls.target.set(0, 0, 0);
		dispatch(setResetView(true));
	}, [camera, controls, dispatch]);

	useEffect(() => {
		if (resetViewState) {
			resetView();
		}
	}, [resetViewState, resetView]);

	// Animates the camera to the default position
	useFrame(() => {
		if (resetViewState) {
			const targetVec = new Vector3(
				defaultCameraPositionObj[0],
				defaultCameraPositionObj[1],
				defaultCameraPositionObj[2]
			);
			camera.position.lerpVectors(camera.position, targetVec, 0.05);
			camera.updateProjectionMatrix();

			if (camera.position.distanceTo(targetVec) < 5) {
				dispatch(setResetView(false));
			}
		}
	});

	// Stops the current reset view animation
	const stopCameraPan = useCallback(() => {
		dispatch(setResetView(false));
	}, [dispatch]);

	// Handles the case where the user clicks on the canvas to pan the camera, stopping the current reset view animation
	useEffect(() => {
		const handleMouseEvent = gl.domElement;
		handleMouseEvent.addEventListener('mousedown', stopCameraPan);
		handleMouseEvent.addEventListener('wheel', stopCameraPan);

		return () => {
			handleMouseEvent.removeEventListener('mousedown', stopCameraPan);
			handleMouseEvent.removeEventListener('wheel', stopCameraPan);
		};
	}, [gl, stopCameraPan]);

	// Handles mousedown events outside of the cube
	handleCanvasClicks.addEventListener('mousedown', onPointerMissedDown);
	function onPointerMissedDown(event: MouseEvent) {
		mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

		raycaster.setFromCamera(mouse, camera);
		if (sectionActive) {
			dispatch(setSectionActive(false)); // Disables beforementioned interactions when mousedown event is active.
		}
		if (ref.current) {
			const intersectsCube =
				raycaster.intersectObjects(ref.current.children); // Checks if there is any mouse intersections with any of the elements in the viewcube.

			// if no intersections, handle the event
			if (intersectsCube.length === 0) {
				if (isCubePressed) {
					dispatch(setIsCubePressed(false));
				}
			}
		}
	}

	// Used to reenable certain features when the mouse is released
	handleCanvasClicks.addEventListener('mouseup', onPointerUp);
	function onPointerUp(event: MouseEvent) {
		mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
		raycaster.setFromCamera(mouse, camera);

		if (!sectionActive) {
			dispatch(setSectionActive(true)); // Reenables certain interactions
		}

		//Specifially here to make sure the hover effect is not removed when the mouse is released on top of the the hoverMesh
		if (hoverMesh.current) {
			// Checks for any intersections with the hover mesh. We don't want the cubeHover effect to be disabled when the mousedown events ends over the hoverMesh.
			const intersectsHoverMesh =
				raycaster.intersectObjects(hoverMesh.current.children);

			// if intersections, handle the event
			if (intersectsHoverMesh.length === 1 && !cubeHover) {
				dispatch(setCubeHover(true));
			}

			if (intersectsHoverMesh.length === 0 && cubeHover) {
				dispatch(setCubeHover(false));
			}
		}
	}

	// Box component for the cube.
	const edgesAndCornersMemo = useMemo(() => {
		// Component for edges and corners. Uses boxGeometry.
		return edgeAndCorners.map((box, index) => (
			<BoxComponent
				key={index}
				boxPosition={box.position}
				boxSize={box.size}
				opacity={opacity}
				fadeInDuration={fadeInDuration}
				fadeOutDuration={fadeOutDuration}
				vbCamera={vbCamera}
				targetCameraPosition={targetCameraPosition}
			/>
		));
	}, [edgeAndCorners]);

	// Plane component for the cube.
	const planesMemo = useMemo(() => {
		return planes.map((plane) => (
			<PlaneComponent
				key={plane.name}
				planePosition={plane.position}
				planeRotation={plane.rotation}
				planeSize={plane.size}
				text={plane.name}
				opacity={opacity}
				fadeInDuration={fadeInDuration}
				fadeOutDuration={fadeOutDuration}
				vbCamera={vbCamera}
				targetCameraPosition={targetCameraPosition}
				cubeSize={cubeSize}
			/>
		));
	}, [cubeSize, planes]);

	// Compas component.
	const compasMemo = useMemo(() => {
		return compas.map((compasValues) => (
			<CompasSliceComponent
				key={compasValues.cardinalDirection}
				cardinalDirection={compasValues.cardinalDirection}
				ringPosition={compasValues.position}
				ringRotation={compasValues.rotation}
				thetaStart={compasValues.thetaStart}
				textPosition={compasValues.textPosition}
				textRotation={compasValues.textRotation}
				direction={compasValues.direction}
				opacity={opacity}
				fadeInDuration={fadeInDuration}
				fadeOutDuration={fadeOutDuration}
				vbCamera={vbCamera}
				targetCameraPosition={targetCameraPosition}
				loadedShapeCache={loadedShapeCache}
				cubeSize={cubeSize}
			/>
		));
	}, [compas, cubeSize]);

	return (
		<>
			{createPortal(
				<>
					<OrthographicCamera
						ref={vbCamera}
						makeDefault={false}
						position={[0, 0, cubeSize * 3]}
					/>
					<instancedMesh
						ref={ref}
						position={[
							size.width / 2 - cubeSize * 2.15,
							size.height / 2 - cubeSize * 2,
							0,
						]}
						// Does not need a raycast, because the cube is the only thing that can be clicked on.
						onPointerMissed={() => dispatch(setIsCubePressed(false))} // if the user clicks outside the cube, the cube is no longer pressed - does not work with drag. Has to be a click.
					>
						{planesMemo}
						{edgesAndCornersMemo}
						<mesh
							position={compasDefaultValues.position}
							rotation={compasDefaultValues.rotation}
						>
							{compasMemo}
						</mesh>
						<lineSegments ref={outlineRef}>
							<edgesGeometry
								args={[new BoxGeometry(cubeSize, cubeSize, cubeSize)]}
							/>
							<lineBasicMaterial color={cubeHover ? configuration.viewCube.lineHover : configuration.viewCube.lineIdle} />
						</lineSegments>
					</instancedMesh>
					{/* This is the plane that is used to detect the hover. */}
					<mesh
						ref={hoverMesh}
						position={[
							size.width / 2 - cubeSize * 2,
							size.height / 2 - cubeSize * 2,
							100,
						]}
					>
						<mesh
							raycast={useVbCamera}
							onPointerEnter={() => handlePointerEnterHoverArea()}
							onPointerOut={() => handlePointerLeaveHoverArea()}
						>
							<planeGeometry args={[cubeSize * 5, cubeSize * 4]} />
							<meshPhongMaterial transparent={true} opacity={0} />
						</mesh>
					</mesh>
					<ambientLight intensity={3.8} />
					<pointLight
						position={[
							size.width / 2 - cubeSize * 1.5 - 150,
							size.height / 2 - cubeSize * 1.25 + 150,
							400,
						]}
						intensity={2.8}
					/>
				</>,
				vbScene
			)}
		</>
	);
};
