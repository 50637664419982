import { useMemo, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyGetSiteSectionsQuery } from '../../Core/Api';
import { Icon } from '../../Core/components/Icon/Icon';
import { PopMenuItems } from '../../Core/components/PopMenu/PopMenu';
import { isDefined } from '../../Core/utils/Logic';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { setLastChosenSectionId } from './Explorer.slice';

const fullSiteKey = 'fullSite';

export const useSectionMenu = () => {
	// Get the Site and Section ID from url params
	const { siteId } = useParams<{ siteId: string }>();
	const location = useLocation();

	const getSectionId = () => {
		// Split the path by '/' and filter out the empty ones that can be left over if we end with a '/'
		const splitPath = location.pathname.split('/').filter((path) => path);

		const [, , moduleName, maybeSectionId] = splitPath;

		if (moduleName === 'explorer' && isDefined(maybeSectionId)) {
			return maybeSectionId;
		}
	};

	const sectionId = getSectionId();

	// Lazy fetcher for the Site Sections API
	const [fetchSiteSections, siteSectionResult] = useLazyGetSiteSectionsQuery();

	// Hook for navigating to the wanted urls
	const navigate = useNavigate();

	// Trigger a fetch when siteId changes
	useEffect(() => {
		if (siteId !== undefined) {
			fetchSiteSections({ siteId });
		}
	}, [siteId, fetchSiteSections]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (siteSectionResult.status === 'fulfilled') {
			dispatch(
				setLastChosenSectionId(
					siteSectionResult.data?.site.sections[0].id ?? ''
				)
			);
		}
	}, [dispatch, siteSectionResult]);

	// Get the menu items
	const items: PopMenuItems = useMemo(() => {
		if (siteSectionResult.data === undefined) {
			return [];
		}

		return [
			{
				key: fullSiteKey,
				label: siteSectionResult.data.site.name,
				icon: (
					<Icon
						width={16}
						name="Home"
						style={{ marginLeft: '6px', marginTop: '-2px' }}
					/>
				),
				isSelected: sectionId === undefined,
			},
			...siteSectionResult.data.site.sections.map(({ id, name }) => ({
				key: id,
				label: name,
				isSelected: id === sectionId,
			})),
		];
	}, [sectionId, siteSectionResult.data]);

	// Get the current selection
	const currentSelection = useMemo(() => {
		if (siteSectionResult.data === undefined) {
			return 'Loading...';
		}

		// If no sectionId is given, return the site name
		if (sectionId === undefined) {
			return siteSectionResult.data.site.name;
		}

		// Otherwise look up the currently selected section name
		const sectionMatch = siteSectionResult.data.site.sections.find(
			({ id }) => id === sectionId
		);

		// Make sure to handle the error state
		if (sectionMatch === undefined) {
			return '';
		}

		return sectionMatch.name;
	}, [sectionId, siteSectionResult]);

	const handleSectionSelect = useCallback(
		(key: string) => {
			// If the full site is chosen, we handle this directly
			if (key === fullSiteKey) {
				navigate(`/riams/${siteId}/explorer`);
				return;
			}

			// Find the ID of the selection
			const sectionId = items.find((item) => key === item.key);

			// If none is found, do nothing
			if (sectionId === undefined) {
				return;
			}

			dispatch(setLastChosenSectionId(sectionId.key));

			// Otherwise navigate to the section
			navigate(`/riams/${siteId}/explorer/${sectionId.key}`);
		},
		[dispatch, items, navigate, siteId]
	);

	const isLoaded = useMemo(() => {
		return siteSectionResult.isSuccess;
	}, [siteSectionResult.isSuccess]);

	return {
		isLoaded,
		currentSelection,
		items,
		onSelectItem: handleSectionSelect,
	};
};
