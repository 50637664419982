import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import {
	BufferAttribute,
	Color,
	DoubleSide,
	RepeatWrapping,
	Vector2,
} from 'three';
import { configuration } from '../../../Core/configuration/configuration';
import { ImprovedNoise } from '../improvedNoise';
import { useSkyBox } from '../useSkyBox';

import waterBump from '../textures/Water_001_DISP.png';

export function WaterAnimated({ level }: { level: number }) {
	// https://jsfiddle.net/prisoner849/5ktzxfuq/

	const perlin = useMemo(() => new ImprovedNoise(), []);

	const size = 200;
	const resolution = 20;

	// Any because PlaneBufferGeoemtry doesn't exist in the three namespace anymore, but it does in three fiber
	const planeRef = useRef<any>();

	let vUv = new Vector2();

	const textureRepeat = 3;

	const envMap = useSkyBox();

	const bumpMap = useTexture(waterBump);
	bumpMap.repeat.set(textureRepeat, textureRepeat);
	bumpMap.wrapS = RepeatWrapping;
	bumpMap.wrapT = RepeatWrapping;

	const color = useMemo(
		() => new Color(configuration.explorer.waterColor),
		[]
	);

	useFrame(({ clock }) => {
		if (!planeRef.current) {
			return;
		}

		const elapsedTime = clock.getElapsedTime();

		const position = planeRef.current.attributes.position;
		const uv = planeRef.current.attributes.uv;
		const waveHeight = 7;
		const waveScale = 5.5;
		const speed = 20;

		for (let i = 0; i < position.count; i++) {
			vUv
				.fromBufferAttribute(uv as BufferAttribute, i)
				.multiplyScalar(waveScale);
			const y = perlin.noise(
				vUv.x,
				vUv.y + (elapsedTime * speed) / 100,
				elapsedTime * 0.1
			);
			position.setZ(i, y * waveHeight);
		}
		position.needsUpdate = true;
	});

	return (
		<mesh rotation={[Math.PI * -0.5, 0, 0]} position={[0, level, 0]}>
			<planeGeometry
				attach="geometry"
				args={[size, size, resolution, resolution]}
				ref={planeRef}
			/>
			<meshStandardMaterial
				attach="material"
				color={color}
				transparent
				opacity={0.5}
				side={DoubleSide}
				envMap={envMap}
				bumpMap={bumpMap}
				bumpScale={0.6}
				metalness={0.55}
				roughness={0.5}
			/>
		</mesh>
	);
}
