import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import './Dashboard.scss';
import { Calendar } from '../../Core/components/Calendar/Calendar';
import { useQueryParam } from '../../Core/hooks/useQueryParam';
import { Breadcrumb } from '../../Core/components/Breadcrumb/Breadcrumb';
import {
	Route,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { DashboardOverview } from './DashboardOverview';
import { DashboardMetric } from './DashboardMetric';
import { setDateRange, useDateRange } from './Dashboard.slice';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { DashboardQuickReference } from './DashboardQuickReference';
import {
	useGetLatestPointTimeQuery,
	useGetQuickReferenceQuery,
} from '../../Core/Api';
import { useBoolean } from 'usehooks-ts';
import { DashboardHeader } from './DashboardHeader';
import { SizeDragButton } from '../../Core/components/Configurator/Configurator';

interface Props {}

export const Dashboard: FC<Props> = () => {
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const { value: quickReference, setValue: setQuickReference } =
		useBoolean(false);
	const [fullscreen, setFullscreen] = useQueryParam('fullscreen');
	const dateRange = useDateRange();

	const paramsArray =
		params['*'] && params['*'].includes('/')
			? params['*'].split('/')
			: params['*']
			? [params['*']]
			: [];

	const { data } = useGetQuickReferenceQuery(
		{ siteId: params.siteId + '', keys: paramsArray },
		{
			skip: !params.siteId,
			refetchOnMountOrArgChange: true,
		}
	);

	const { data: pointTimeData } = useGetLatestPointTimeQuery({
		siteId: params.siteId + '',
	});

	useEffect(() => {
		if (!dateRange && pointTimeData) {
			const ONE = 1;
			const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

			/**
			 * Influx excludes the "stop" time of a query, hence we add 1 milisecond
			 * to the timestamp, in order to have our queried value be included
			 *
			 * In order to get a sane start date, we subtract 30 days from the timestamp queried
			 */
			dispatch(
				setDateRange([
					new Date(pointTimeData - THIRTY_DAYS),
					new Date(pointTimeData + ONE),
				])
			);
		}
	}, [pointTimeData, dateRange, dispatch]);

	const isFullscreen = fullscreen === 'true';

	const handleToggleFullscreen = () => {
		setFullscreen(isFullscreen ? 'false' : 'true');
	};

	/**
	 * Handler for setting the selected date range
	 * If the input is not an array, we do nothing
	 */
	const handleSelectedDateRangeChange = useCallback(
		(dates: [Date, Date]) => {
			dispatch(setDateRange(dates));
		},
		[dispatch]
	);

	/**
	 * Click handler for selecting a metric
	 * Will handle navigating to the path of the metric
	 */
	const handleSelectMetric = (id: string) => {
		const currentPaths = params['*'];

		if (currentPaths?.includes(id)) {
			return;
		}
		navigate((currentPaths ? `${currentPaths}/` : '') + id + search);
	};

	const [width, setWidth] = useState(50);

	function updateSize() {
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', () => {
			window.removeEventListener('mousemove', handleMouseMove);
		});
	}

	function handleMouseMove(event: MouseEvent) {
		const widthPx = window.innerWidth - event.clientX;
		const widthVw = (widthPx / window.innerWidth) * 100 + 1;
		setWidth(widthVw);
	}

	const containerStyle = {
		width: isFullscreen ? '100%' : `${width}vw`,
		maxWidth: isFullscreen ? 'none' : '70vw',
		minWidth: isFullscreen ? 'none' : '500px',
	};

	return (
		<section
			className={classNames(['Dashboard'], {
				'Dashboard--Fullscreen': isFullscreen,
				Dashboard__ShowQuickReference: quickReference,
			})}
		>
			<div className="Dashboard__Content" style={containerStyle}>
				<SizeDragButton onMouseDown={() => updateSize()} />
				<DashboardHeader
					toggleFullscreen={handleToggleFullscreen}
					openQuickReference={() => setQuickReference(true)}
					quickReferenceOpen={quickReference}
					siteId={params.siteId!}
				/>
				<div className="Dashboard__Navigation">
					<Breadcrumb
						basePath={`/riams/${params.siteId}/dashboard`}
						baseTitle="Overview"
					/>
					<div className="Dashboard__Calendar">
						{dateRange && (
							<Calendar
								onChange={handleSelectedDateRangeChange}
								startDate={dateRange[0]}
								endDate={dateRange[1]}
							/>
						)}
					</div>
				</div>

				{dateRange && dateRange.length === 2 && (
					<Routes>
						<Route
							path="/"
							element={
								<DashboardOverview
									isFullscreen={isFullscreen}
									handleSelectMetric={handleSelectMetric}
								/>
							}
						/>
						<Route
							path="/*"
							element={
								<DashboardMetric
									isFullscreen={isFullscreen}
									handleSelectMetric={handleSelectMetric}
								/>
							}
						/>
					</Routes>
				)}
			</div>
			<div className="Dashboard__QuickAccess">
				<DashboardQuickReference
					closeQuickReference={() => setQuickReference(false)}
					markdown={data?.markdown ?? ''}
				/>
			</div>
		</section>
	);
};
