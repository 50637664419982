import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ViewCubeSizeOptions = 'small' | 'regular' | 'large' | 'veryLarge';

export interface ViewCubeSize {
	label: ViewCubeSizeOptions;
	value: number;
}

export interface ExplorerSettingsState {
	explorerSettings: boolean;
	viewCubeEnabled: boolean;
	viewCubeSize: ViewCubeSize;
	seaEnabled: boolean;
	seaElevation: number;
	soilEnabled: boolean;
	displayStructureColors: boolean;
	displayLegendInformation: boolean;
}

export const viewCubeSizeMap: Record<ViewCubeSizeOptions, number> = {
	small: 45,
	regular: 60,
	large: 75,
	veryLarge: 90,
};

const initialState: ExplorerSettingsState = {
	explorerSettings: false,
	viewCubeEnabled: true,
	viewCubeSize: {
		label: 'regular',
		value: viewCubeSizeMap['regular'],
	},
	seaEnabled: true,
	seaElevation: 0,
	soilEnabled: true,
	displayStructureColors: true,
	displayLegendInformation: true,
};

export const explorerSettings = createSlice({
	name: 'explorerSettings',
	initialState,
	reducers: {
		toggleExplorerSettings: (state) => {
			state.explorerSettings = !state.explorerSettings;
		},
		toggleViewCubeEnabled: (state) => {
			state.viewCubeEnabled = !state.viewCubeEnabled;
		},
		setViewCubeSize: (state, action: PayloadAction<ViewCubeSizeOptions>) => {
			state.viewCubeSize = {
				label: action.payload,
				value: viewCubeSizeMap[action.payload],
			};
		},
		toggleSeaEnabled: (state) => {
			state.seaEnabled = !state.seaEnabled;
		},
		setSeaElevation: (state, action: PayloadAction<number>) => {
			state.seaElevation = action.payload;
		},
		toggleSoilEnabled: (state) => {
			state.soilEnabled = !state.soilEnabled;
		},
		toggleDisplayStructureColors: (state) => {
			state.displayStructureColors = !state.displayStructureColors;
		},
		toggleDisplayLegendInformation: (state) => {
			state.displayLegendInformation = !state.displayLegendInformation;
		},
	},
});

export const {
	toggleExplorerSettings,
	toggleViewCubeEnabled,
	setViewCubeSize,
	toggleSeaEnabled,
	setSeaElevation,
	toggleSoilEnabled,
	toggleDisplayStructureColors,
	toggleDisplayLegendInformation,
} = explorerSettings.actions;
