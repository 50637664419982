import React from 'react';
import { ReactNode } from 'react';
import { InstancedMesh, Matrix4, Object3D, Quaternion, Vector3 } from 'three';
import { ModelElement } from '../models/ModelElement.entity';
import { SelectionMesh } from './SelectionMesh';

const diameter = 1;

const matrixHelper = new Object3D();

// Align square cylinders (4 segments) with a horizontal top part
const cylinderAngle = Math.PI * 0.25;

interface Props {
	pipes: ModelElement[];
	pipeSegments?: number;
	pipeWidth?: number;
}
export const PipeMesh = ({
	pipes,
	pipeSegments = 6,
	pipeWidth = 0.5,
	instanceRef = React.createRef<InstancedMesh>(),
}: Props & { instanceRef?: React.RefObject<InstancedMesh> }) => {
	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<cylinderGeometry
			args={[pipeWidth, pipeWidth, 1, pipeSegments, 1, false, cylinderAngle]}
			attach="geometry"
		>
			{children}
		</cylinderGeometry>
	);

	// The function that maps the element to the 3d space
	const placementFn = (element: ModelElement) => {
		const [, , , from, to] = element;

		// The as number[] here are only included as TS seems to not be able to correctly
		// inter the ...coordinates: number[][] in ModelElement
		const vectorFrom = new Vector3(...(from as number[]));
		const vectorTo = new Vector3(...(to as number[]));
		const length = new Vector3().subVectors(vectorTo, vectorFrom).length();
		const direction = new Vector3()
			.subVectors(vectorTo, vectorFrom)
			.normalize();

		const tempObject2 = matrixHelper.clone();

		tempObject2.applyMatrix4(
			new Matrix4()
				.multiply(
					new Matrix4().makeTranslation(...(from as [number, number, number]))
				)
				.multiply(
					new Matrix4().makeRotationFromQuaternion(
						new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), direction)
					)
				)
				.multiply(new Matrix4().makeScale(diameter, length, diameter))
				.multiply(new Matrix4().makeTranslation(0, 0.5, 0))
		);

		tempObject2.updateMatrix();

		return tempObject2.matrix;
	};

	return (
		<SelectionMesh
			category="element"
			components={pipes}
			InstancedGeometry={InstancedGeometry}
			placementFn={placementFn}
			instanceRef={instanceRef}
		/>
	);
};
